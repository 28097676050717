import { css } from '@linaria/core';
import { palette } from '@smile/tokens/palette';
import { from, Media, until } from '@theway/helpers-css/breakpoints';
import { container } from '@theway/helpers-css/breakpoints/breakpoints';
import { customVariable } from '@theway/helpers-css/variables';

export const firstBrandVar = customVariable('first-brand-color', (x) => `${x}`);
export const secondBrandVar = customVariable(
	'second-brand-color',
	(x) => `${x}`,
);

export const heightVar = customVariable('height', (x) => `${x}px`);

export const mobile = css`
	container-name: content;
	container-type: inline-size;

	display: grid;
`;
export const desktop = css`
	display: grid;
`;

export const component = css`
	position: relative;

	display: grid;
	height: 100svh;
	max-height: 568px;
	width: calc(100vw - 16px);
	max-width: 374px;
	margin-inline: auto;

	${from(Media.SM)} {
		width: 700px;
		max-width: none;
		height: 450px;
	}
`;

export const componentWrapper = css`
	position: absolute;
	inset: 0;

	display: grid;
	width: 100%;
	height: 100%;

	border-radius: 16px;
	overflow: hidden;
`;

export const nativeMobile = css`
	${until(Media.SM)} {
		width: 100%;
		max-width: none;
		border-radius: 16px 16px 0 0;
	}
`;

export const nativeDesktop = css`
	${from(Media.SM)} {
	}
`;

export const normalMobile = css`
	${until(Media.SM)} {
	}
`;

export const normalDesktop = css`
	${from(Media.SM)} {
	}
`;

export const wrapper = css`
	position: relative;

	display: grid;

	width: 100%;
	height: 100%;

	*:where(.${mobile}) & {
		align-content: start;
	}

	*:where(.${desktop}) & {
		grid-template-columns: 350px 1fr;
	}
`;

export const content = css`
	position: absolute;

	display: grid;
	grid-template-rows: 1fr auto;

	box-shadow: 0px -12px 24px -8px rgba(0, 0, 0, 0.12);

	*:where(.${mobile}) & {
		inset-inline: 0;
		bottom: 0;

		min-height: 350px;
		width: 100%;

		border-radius: 16px 16px 0 0;
	}

	*:where(.${desktop}) & {
		inset-block: 0;
		right: 0;

		min-height: 100%;
		width: min-content;

		border-radius: 16px 0 0 16px;

		overflow: hidden;
	}
`;

export const contentWrapper = css`
	position: relative;
	height: 100%;

	*:where(.${mobile}) & {
	}

	*:where(.${desktop}) & {
		display: grid;
		grid-template-rows: auto 1fr;
	}
`;
export const close = css`
	position: absolute;
	top: 11px;
	right: 14px;
`;

export const decoration = css`
	position: relative;

	box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);

	*:where(.${mobile}) & {
		width: calc(100% - 48px);
		margin-inline: auto;
		aspect-ratio: 1.8;

		border-radius: 16px;

		${container('content', 'width < 358px')} {
			width: calc(100% - 40px);
		}
	}

	*:where(.${desktop}) & {
		width: 326px;
		height: 100%;
	}
`;

export const decorationWrapper = css`
	position: absolute;
	inset: 0;

	overflow: hidden;

	*:where(.${mobile}) & {
		border-radius: 16px;
	}
`;

export const hidden = css`
	display: none;
`;

export const slide = css`
	position: absolute;
	inset: 0;

	& img {
		width: 100%;
		height: 100%;

		object-fit: cover;
		object-position: center;
	}
`;

export const partnership = css`
	position: absolute;

	display: flex;

	${until(Media.SM)} {
		top: -34px;
		left: 50%;
		transform: translateX(-50%);
	}

	${from(Media.SM)} {
		top: 50%;
		left: calc(50% - 25px);

		transform: translate(-50%, -50%);
	}
`;

export const dual = css`
	position: absolute;

	display: flex;
	width: auto;
	height: ${String(heightVar)};

	& svg {
		width: auto;
		height: 100%;
	}

	${until(Media.SM)} {
		top: 34px;
		left: 50%;

		transform: translateX(-50%);
	}

	${from(Media.SM)} {
		top: 50%;
		left: calc(50% - 25px);

		transform: translate(-50%, -50%);
	}
`;

export const firstBrand = css`
	position: absolute;
	inset-block: 0;
	left: 0;

	aspect-ratio: 1/1;
	display: block;
	height: 100%;

	border-radius: 50%;

	background-color: transparent;

	& img {
		position: absolute;
		inset: 0;

		width: 100%;
		aspect-ratio: 1/1;

		object-fit: contain;
		object-position: center;
	}
`;

export const secondBrand = css`
	position: absolute;
	inset-block: 0;
	right: 0;

	aspect-ratio: 1/1;
	display: block;
	height: 100%;

	border-radius: 50%;

	background-color: transparent;

	& img {
		position: absolute;
		inset: 0;

		width: 100%;
		aspect-ratio: 1/1;

		object-fit: contain;
		object-position: center;
	}
`;

export const share = css`
	position: absolute;
	top: 8px;
	right: 8px;
	display: flex;
	align-items: center;
	justify-content: center;

	width: 30px;
	aspect-ratio: 1/1;

	color: #000;

	border-radius: 50%;

	background-color: rgba(255, 255, 255, 0.3);

	${from(Media.SM)} {
		top: 16px;
		left: 16px;
		right: auto;
	}

	& svg {
		width: 16px;
		aspect-ratio: 1/1;

		color: inherit;
	}
`;

export const badge = css`
	position: absolute;
	left: -9px;

	display: flex;
	align-items: center;

	width: auto;
	height: 20px;
	padding-block: 2px;
	padding-inline: 8px;

	border-radius: 0 9999px 9999px 0;

	background-color: var(--custom-accent-color, ${palette.cyanBurn[500]});

	*:where(.${mobile}) & {
		top: 12px;
	}

	*:where(.${desktop}) & {
		top: 62px;
	}

	[data-theme='zip'] & {
		background-color: #dcf995;
	}

	[data-theme='zip-us'] & {
		background-color: #dcf995;
	}

	[data-theme='humanitix'] & {
		background-color: rgb(168, 255, 194);
	}

	[data-theme='ebay'] & {
		background-color: ${palette.cyanBurn[500]};
	}

	[data-theme='try-booking'] & {
		background-color: ${palette.cyanBurn[500]};
	}
`;

export const triangle = css`
	position: absolute;
	bottom: -4px;
	left: 0;
`;

export const preview = css`
	&::after {
		position: absolute;
		inset: 0;
		content: '';

		display: block;
		width: 100%;
		height: 100%;

		box-shadow: inset 0 0 0 1.5px #edecf1;
		border-radius: 16px;
	}
`;
